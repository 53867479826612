<template>
  <div
    v-bind="$attrs"
    class="hm-popover-item"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HMPopoverMenuItem',
};
</script>
<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.hm-popover-item {
  padding: $popover-item-spacing-y $popover-item-spacing-x;
  border-bottom: 1px solid $light-gray;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: $light-gray;
  }
  & > a {
    display: block;
    margin: (-$popover-item-spacing-y) (-$popover-item-spacing-x);
    padding: $popover-item-spacing-y $popover-item-spacing-x;
  }
}
</style>
